
.color1 {
  background-color: #6be8da;
}
.color2 {
  background-color: #04b4a4;
}
.color3 {
  background-color: #02e2c4;
}
.color4 {
  background-color: #1f3b7d;
}
.color5 {
  background-color: #cedfe8;
}

.sideBar {
  background-color: #cedfe8;
}

/* Hide scrollbar for Chrome, Safari and Opera */
/* .sideBar::-webkit-scrollbar {
  display: none!important;
} */

.logo {
  background-color: #cedfe8;
}

.header {
  background-color: #1f3b7d;
}

.main {
  background-color: #FBF8F7  ;
}

.pointer {
   cursor: pointer; 
}

.fill {
  height: 100vh!important;
}


.hover:hover {
  background-color: lightgrey;
}

.searchList {
  position:relative; z-index:10
}

.alwaysShow {
  z-index:999999 !important;
}