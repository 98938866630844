
$sidebar-bg-color: #cedfe8 !default
$sidebar-color: #1f3b7d !default

$sidebar-width: 210px !default

$sidebar-collapsed-width: 60px !default

$highlight-color: #04b4a4 !default
$submenu-bg-color: #d6e6f0 !default
$submenu-bg-color-collapsed: #cedfe8 !default
$icon-bg-color: #cedfe8 !default

$icon-size: 20px !default
$breakpoint-xs: 480px !default
$breakpoint-sm: 576px !default
$breakpoint-md: 768px !default
$breakpoint-lg: 992px !default
$breakpoint-xl: 1200px !default

@import "~react-pro-sidebar/dist/scss/styles.scss"
