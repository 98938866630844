.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}

.App {
  text-align: center;
}

.renk1 {
  background-color: #6be8da;
}
.renk2 {
  background-color: #04b4a4;
}
.renk3 {
  background-color: #02e2c4;
}
.renk4 {
  background-color: #1f3b7d;
}
.renk5 {
  background-color: #cedfe8;
}

.beyaz {
  background-color: white;
}

.sideBar {
  background-color: #cedfe8;
  min-height: 100vh;
}

.logo {
  background-color: #cedfe8;
}

.ustMenu {
  background-color: #1f3b7d;
  min-height: 60px;
}

.pointer {
  cursor: pointer;
}
